<template>
  <div>
    <nav id="merchant-portal-header" class="navbar navbar-inverse">
      <div class="container-fluid">
        <div class="row">
          <div id="merchant-portal-header-logo-bg" class="col-lg-4 col-md-4 col-sm-5 col-xs-12">
            <a @click="merchantOderGroup()">
              <img id="mer-portal-log" :src="this.$store.state.colourLogo" title="Your Store" alt="Your Store" />
            </a>
          </div>
          <div id="merchant-portal-header-right" class="col-lg-8 col-md-8 col-sm-7 col-xs-12">
            <div class="row">
              <div class="col-lg-11 col-md-11 col-sm-11 col-xs-12 text-right"  v-if="this.$store.state.merchant_accessToken === null">
                <B class="merchant-portal-hedding-text">Cochchi Merchant</B>
              </div>
              <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 text-right"  v-if="this.$store.state.merchant_accessToken != null && userRole === 1">
                <ul>
                <li class="merchant-portal-hedding-text" v-if="this.$store.state.merchant_accessToken !== null">
                    <B>Cochchi Merchant | </B>
                  <!-- </a> -->
                  <a href="./HelpCenterHome.html" target="_blank">
                    <B class="merchant-portal-hedding-text-help">Help Center</B>
                  </a>
                </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div id="mer-portal-nav-div" class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                <ul v-if="this.$store.state.merchant_accessToken != null" id="mer-portal-nav" class="nav navbar-nav navbar-right">
                  <li v-if="this.$store.state.merchant_accessToken !== null" style="margin-right: 5px;">
                    <a @click="getChatUsers()" data-toggle="modal" data-target="#mdlMerchantChat">
                      <i class="fa fa-comments"></i>&nbsp;&nbsp;
                      <B>Chat Now</B>
                    </a>
                  </li>
                  <li v-if="this.$store.state.merchant_accessToken !== null" class="signin-w">
                    <a @click="pushMerchantProfile">
                      <i class="fa fa-user"></i>&nbsp;&nbsp;
                      <B>Profile</B>
                    </a>
                  </li>
                  <li><a>
                    <div class="dropdown">
                        <button id="dropdownMenuAlertButton" class="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fa fa-bell"></i>
                          <b-badge
                            pill
                            variant="success"
                            class="mb-2"
                            style="padding: 4px; border-radius: 50%;background-color: red; margin-bottom: 3px; font-size: 8px;"
                          >{{ alerts.length }}</b-badge>&nbsp;&nbsp;
                          <B>Alerts</B>
                        </button>
                        <div
                          v-show="alerts.length != 0" 
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuAlertButton"
                          style="padding: 10px; width: 450px; margin-top: 35px; position:absolute;"
                        >
                          <div style="max-height:300px; overflow: auto; ">
                            <table
                              class="table table-borderless hoverTable tble-alerts"
                              style="margin-bottom: 0px;"
                            >
                              <tr
                                role="menuitem"
                                style="cursor: pointer;"
                                v-for="(item, index) in alerts"
                                :key="index"
                                @click="alertPush(item)"
                              >
                                <td
                                  style="; padding:2px; display: flex;
                                                          align-items: center;justify-content: center;"
                                >
                                  <img
                                    data-v-596f113e
                                    width="200px"
                                    :src="item.image"
                                    alt="Thumb Image"
                                    title="Thumb Image"
                                    class="img-thumbnail"
                                  />
                                </td>
                                <td
                                  style="padding: 10px; color: black;"
                                >{{ item.notification }}
                                  <p>Date - {{item.create_date.split(" ")[0]}}<span style="margin-left: 10px;">Time - {{item.create_date.split(" ")[1]}}</span></p>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <button id="readAllBtn" @click="readAll()">Read All</button>
                        </div>
                      </div> 
                    </a>
                  </li>
                  <li>
                    <a @click="logout">
                      <span>
                      <i class="fa fa-unlock"></i>
                        &nbsp;&nbsp;
                        <B>Log Out</B>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- Merchant Chat Modal -->
      <div
        class="modal"
        id="mdlMerchantChat"
        tabindex="-1"
        role="dialog"
        style="right:0px !important; top: auto; bottom: 0px;  left:auto;"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="border-radius: 0px !important; ">
            <div class="modal-body">
              <CommonChatWindow
                ref="childChatComm"
                v-bind:utype="'merchant'"
                v-bind:chatTargets="chatUsers"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Merchant Chat Modal End -->
  </div> 
</template>
<script>
import APIProfile from "../../mixins/merchant/APIProfile";
import mixinStore from "../../mixins/merchant/APIStore";
import CommonChatWindow from "@/components/common/chatCommon.vue";
export default {
  name: "TopHeader",
  components: {
    CommonChatWindow
  },
  mixins: [APIProfile, mixinStore],
  props: {
    mid: String
  },
  data() {
    return {
      loggedin: false,
      alerts: [],
      copyAlerts: [],
      chatUsers: [],
      userRole: ""
    };
  },
  created() {
    this.haddleRole();
  },
  watch: {
    $route(to, from) {
      this.connectSocket();
    }
  },
  sockets: {},
  mounted() {
    this.connectSocket();
  },
  methods: {
    haddleRole: function() {
      let mdata = JSON.parse(localStorage.merchant_data);
      this.userRole = mdata.role;
    },
    getChatUsers: async function() {
      let response = await this.getMerchantChatUsers();
      if (response.length > 0) {
        for (let index = 0; index < response.length; index++) {
          if (response[index]._id === null) {
            response.splice(index, 1);
          }
        }
        this.chatUsers = response;
        this.$refs.childChatComm.userChatByTraget(this.chatUsers[0]._id);
      }
    },
    connectSocket: async function() {
      try {
        let res = await this.getMerchantAlert();
        
        // let arr = res.filter(function(val) {
        //   return val.notification_type === 4;
        // });
        this.alerts = res;

      } catch (error) {
        throw error;
      }
      
      let response = await this.getProfileBacicInfo();
      this.$socket.client.io.opts.query = {
          token: response._id,
          token_type: 2
      }

      this.$socket.client.connect();
      let self = this;
      this.$socket.client.on('socket/merchant/notification', function(data) {
          if(data.payload.data.length > 0){
              self.alerts = data.payload.data;
              // Object.assign(this.alerts, data.payload.data) 
          }
      });
      let responsestore = await this.getStores();
    },
    resetWindow() {
      var element = document.getElementById("dropdownwindow");
      element.style.opacity = null;
      element.style.visibility = null;
    },
    readAll: async function(){
      let idobj = [];
      let obj1 ={
        id:[]
      };
      if (this.alerts.length > 0) {
        this.alerts.map(obj=>{
          obj1.id.push(obj._id);
        });
        
      }
      idobj = obj1;
      try {
        let response = await this.allWach(idobj);
        if (response) {
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "All Alerts Cleared!",
            showConfirmButton: false,
            timer: 1500
          });
          this.connectSocket();
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 1500
        });
        }
    },
    alertPush: async function(item) {
      // this.$store.state.merchant_selectedstore = item.store;
      // localStorage.setItem("merchant_selectedstore", item.store);
      // this.$store.dispatch("fetchMerchant_selectedstore");
      let idobj = {_id:""};
      idobj._id = item._id;
      try {
        let response = await this.singalWach(idobj);
        if (response) {
          this.connectSocket();
          this.$router.push({
            name: "Product Configure",
            params: { id: item.product }
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
      // document.getElementById("dropdownwindow").style.opacity = 0;
      // document.getElementById("dropdownwindow").style.visibility = 'hidden';
      // let obj = {
      //     "_id": aid
      // }
      // this.$router.push('/merchant-notification-center?type=' + notification_type).catch(err => {});
      // let response = await this.putAlertClick(obj);
      // switch (notification_type) {
      //     case 1:
      //         // 1 - customer-question
      //         break;
      //     case 2:
      //         //  2 - order
      //         break;
      //     case 3:
      //         //  3 - announce
      //         break;
      //     case 4:
      //         // 4 - product
      //         break;
      //     case 5:
      //         //  5 - rating
      //         break;
      //     default:
      //         break;
      // }
    },
    handleByOnSlt: function() {
      this.$router.push("/merchant-landing-new").catch(err => {});
      // this.$router.push('/').catch(err => {});
    },
    handleByOnSltlanding: function() {
      this.$router.push("Merchant Landing New").catch(err => {});
    },
    handleMerchantProfile: function() {
      this.$router.push("profile-configure").catch(err => {});
    },
    login() {
      this.$router.push("/buyer-login").catch(err => {});
    },
    logout() {
      localStorage.removeItem("order_group_id");
      localStorage.removeItem("pending_order_group_id");
      localStorage.removeItem("cancelled_order_group_id");
      this.$store.dispatch("logoutMerchant");
      this.$router.push("/merchant-landing-new").catch(err => {});
    },
    register() {
      this.$router.push("/merchant-register").catch(err => {});
    },
    pushMerchantProfile() {
      this.$router.push("/merchant-profile").catch(err => {});
    },
    merchantLanding() {
      this.$router.push("/merchant-profile").catch(err => {});
    },
    merchantOderGroup() {
      if (this.$store.state.merchant_accessToken !== null) {
        this.$router.push("/merchant-dashbord").catch(err => {});
      }
    }
  }
};
</script>
<style scoped>
#merchant-portal-header{
  border-radius: 0px;
  background: rgb(0,104,181);
  background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%);
  border: none;
  color: white;
}

#readAllBtn {
  width: 100%;
  text-align: center;
  color: black;
  height: 30px;
}

.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#merchant-portal-header-logo-bg {
  height: 147px;
  background: url("https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1608183515/cochchi/portal_user/1608183514902.png") no-repeat left center !important;
}

#merchant-portal-header-right {
  height: 147px;
  padding-top: 1%;
}

#mer-portal-nav-div {
  padding-top: 58px;
}

#mer-portal-nav li a {
  color: white !important;
}

#mer-portal-nav li a:hover {
  color: black !important;
}

#mer-portal-log {
  max-width: 23%;
  margin-top: 10px;
  margin-left: 5%;
}

.merchant-portal-hedding-text {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 0.07345em;
  color: white !important;
}

.merchant-portal-hedding-text-help {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 0.07345em;
  color: white !important;
}

.merchant-portal-hedding-text-help:hover {
  color: black  !important;
}

.dropdown-toggle {
  color: white !important;
  background-color: transparent;
  border: none;
  font-weight: 700;
}

.dropdown-toggle:hover {
  color: black !important;
}


@media screen and (min-width: 2200px) {
  #mer-portal-log {
    max-width: 18%;
    margin-top: 10px;
    margin-left: 7%;
  }
}

@media screen and (min-width: 2700px) {
  #mer-portal-log {
    max-width: 15%;
    margin-top: 10px;
    margin-left: 5%;
  }
}

@media screen and (max-width: 1700px) {
  #mer-portal-log {
    max-width: 26%;
    margin-top: 10px;
    margin-left: 7%;
  }
}

@media screen and (max-width: 1440px) {
   #mer-portal-log {
    max-width: 30%;
    margin-top: 10px;
    margin-left: 10%;
   }
}

@media screen and (max-width: 1200px) {
  #mer-portal-log{
      max-width: 35%;
      margin-top: 10px;
      margin-left: 12%;
  }
}

@media screen and (max-width: 1024px) {}
@media screen and (max-width: 992px) {
  #mer-portal-log{
      max-width: 35%;
      margin-top: 10px;
      margin-left: 12%;
  }
}
@media screen and (max-width: 991px) {
  #mer-portal-log{
      max-width: 40%;
      margin-top: 10px;
      margin-left: 14%;
  }

  .merchant-portal-hedding-text-help {
    margin-right: 8px;
  }
}
@media screen and (max-width: 767px) {
  #mer-portal-log{
    max-width: 18%;
    margin-top: 10px;
    margin-left: 6%;
  }

  #mer-portal-nav-div {
    padding-top: 5px;
  }

  #merchant-portal-header-right{
      height: auto;
      padding-top: 1%;
  }
  #merchant-portal-header-logo-bg {
     height: auto;
  }

  #mer-portal-nav {
    display: flex;
    justify-content: center;
  }

  .merchant-portal-hedding-text{
    text-align: center !important;
  }
}
@media screen and (max-width: 875px) {}
@media screen and (max-width: 575px) {
  #mer-portal-log {
    max-width: 21%;
    margin-top: 10px;
    margin-left: 10%;
  }
  .navbar-nav {
      margin: 0px !important;
  }

  .merchant-portal-hedding-text-help {
    margin-right: 0px;
  }
}

@media screen and (max-width: 475px){
  #merchant-portal-header-logo-bg {
    background: white !important;
    text-align: center;
  }

  #mer-portal-log{
    max-width: 24%;
    margin-top: 10px;
    margin-left: 0px;
  }

}

@media screen and (max-width: 400px) {
  .nav > li > a {
    padding: 6px 4px !important;
  }
}

@media screen and (max-width: 425px) {
  #mer-portal-log{
    max-width: 24%;
    margin-top: 10px;
  }
}
@media screen and (max-width: 350px) {}
@media screen and (min-width: 1575px) {}
@media screen and (min-width: 1775px) {}
@media screen and (min-width: 1920px) {}
@media screen and (min-width: 2000px) {}
@media screen and (min-width: 2300px) {}
@media screen and (max-width: 1280px) {}
@media (min-width: 320px) {}

</style>
