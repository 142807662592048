<template>
  <div
    class="wrapper"
    style="
      background-repeat: no-repeat;
      background-position: right top;
      background-attachment: fixed;
      background: #ffffff;
    "
  >
    <!-- <div
      style="background-image: url(https://res.cloudinary.com/persystance-network/image/upload/q_auto/v1589124594/slt/portal_user/1589124594178.png);
  background-repeat: no-repeat; background-attachment: fixed;"
    > -->
    <div>
      <div class="main-panel">
        <top-navbar v-bind:mid="mid"></top-navbar>

        <div
          class="container"
          style="
            padding-top: 15px;
            padding-left: 12px;
            padding-right: 12px;
            background: #ffffff;
          "
        >
          <div class="row">
            <div class="col-sm-5 col-md-5" style="margin-top: -20px">
              <ul class="breadcrumb mer-bc-list">
                <li>
                  <a @click.prevent.stop="breadpush('/merchant-dashbord')">
                    <i
                      style="font-size: 30px"
                      id="homeIcon"
                      class="fa fa-home home-bedcrumb"
                    ></i>
                  </a>
                </li>
                <li v-for="item in lists" :key="item.text">
                  <a @click.prevent.stop="breadpush(item.href)">
                    {{ item.text }}
                  </a>
                </li>
              </ul>
            </div>
            <!-- $route.name !== 'Product Configure' && -->
            <div
              class="col-sm-7 col-md-7"
              v-if="
                userRole !== 2 &&
                $route.name !== 'Merchant Store' &&
                $route.name !== 'Merchant Profile' &&
                $route.name !== 'preferences' &&
                $route.name !== 'Stock Configure Freeshipping Bulk' &&
                $route.name !== 'Merchant Dispute Lines' &&
                $route.name !== 'Merchant Dashbord' &&
                $route.name !== 'gallery list' &&
                $route.name !== 'Notification Center'
              "
            >
              <div class="row">
                <div class="col-sm-3"></div>
                <div
                  class="col-sm-4 text-right"
                  style="font-weight: 600; margin-top: 9px"
                >
                  Store Name
                </div>
                <div class="col-sm-4" style="width: 30.6%">
                  <select
                    v-model="selected_store"
                    class="form-control select-arrow"
                    id="selectstore"
                    name="country_id"
                  >
                    <option value="-1">All</option>
                    <option
                      v-for="(item, val) in store"
                      :key="val"
                      :value="item._id"
                    >
                      {{ item.store_name }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-1"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 mer-info-block">
              <div>
                <h3>User : {{ fullName }}</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <aside class="col-sm-4 col-md-3 content-aside" id="column-left">
              <BasicLeftMenu v-bind:data="this.leftmenudata" />
            </aside>
            <div class="col-md-9 col-sm-8">
              <span v-if="showStages">
                <div v-if="currentstage < 7" class="container">
                  <ul class="progressbar" v-if="userRole === 1">
                    <li :class="[currentstage >= 1 ? 'active' : '']">
                      Personal Information
                    </li>
                    <li :class="[currentstage >= 2 ? 'active' : '']" >
                      Addresses
                    </li>
                    <li :class="[currentstage >= 3 ? 'active' : '']">KYC</li>
                    <li :class="[currentstage >= 4 ? 'active' : '']">Bank</li>
                    <li
                      v-if="account_type == 2"
                      :class="[currentstage >= 5 ? 'active' : '']"
                    >
                      Business Registration
                    </li>
                    <li :class="[currentstage >= 6 ? 'active' : '']">
                      <span>Create Store</span>
                    </li>
                    <li :class="[currentstage = 7 ? 'active' : '']">
                      <span>Delivery Details</span>
                    </li>
                  </ul>
                  <br />
                </div>
              </span>

              <dashboard-content v-model="selected_store"></dashboard-content>
              <div v-if="$route.name === 'Product Configure Bulk'" style="color: red; font-size: 13px;">
                <p>Instructions:</p>
                <p>Step 1: Download the Category, Attribute and Brand List file along with the Sample File. The sample file is what you will upload once completed. </p>
                <p>Step 2: The sample file contains one record as an indication how the file must be filled. Copy and paste details from the Category, Attribute and Brand Lists such that all columns are filled and have no spelling errors.</p>
                <p>Step 3: You can enter image URLs to the sheet. The URL can be from an external source or you can add images in the Gallery section in your Cochchi.lk portal and copy the URL for the relevant image there. You can enter multiple images by separating the URLs with a comma (,)</p>
                <p>Step 4: Please ensure your store name and delivery partner details are entered correctly with no spelling error.</p>
                <p>Step 5: Click on 'Choose File' and select the completed sheet and then click on Upload.</p>
                <p style="margin-top: 20px;">Call our hotline if you encounter any technical errors.</p>
              </div>
            </div>
          </div>
        </div>

        <content-footer class="footer-container typefooter-1"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
// import TopNavbar from "./MerchantDashboard/TopNavbar.vue";
import TopNavbar from "./MerchantDashboard/TopNavbarMerchantPortal.vue";
import ContentFooter from "./MerchantDashboard/ContentFooter.vue";
import DashboardContent from "./MerchantDashboard/Content.vue";
import BasicLeftMenu from "../components/common/basic_left_menu";

import sidebarLinksMerchant from "../sidebarLinksMerchant";
import sidebarLinksMerchantRole2 from "../sidebarLinksMerchantRole2";
import sidebarLinksMerchantRole7 from "../sidebarLinksMerchantRole7";
import mixinStore from "@/mixins/merchant/APIStore";
import mixinProfile from "@/mixins/merchant/APIProfile";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    BasicLeftMenu,
    sidebarLinksMerchant,
    sidebarLinksMerchantRole2,
    sidebarLinksMerchantRole7,
    // UserMenu
  },
  mixins: [mixinStore, mixinProfile],
  data() {
    return {
      fullName: "",
      menutitle: "SLT ADMIN",
      breadcrumb: [
        {
          text: "Dashboard",
          href: "#",
          active: true,
        },
      ],
      leftmenudata: {},
      store: [],
      selected_store: "",
      userRole: "",
      mid: "",
      currentstage: 1,
      account_type: 1,
      showStages: false,
    };
  },
  onIdle() {
    this.$swal
      .fire({
        title: "Your session is about to expire",
        text:
          "You will be logged out in 30 minutes. Do you want to stay signed in?",
        type: "warning",
        confirmButtonText: "Yes, keep me signed in!",
        timer: 10000,
      })
      .then((result) => {
        if (result.value) {
        } else {
          localStorage.removeItem("merchant_token");
          localStorage.removeItem("merchant_type");
          localStorage.removeItem("login_merchant");
          localStorage.removeItem("merchant_data");
          this.$store.dispatch("logoutMerchant");
          this.$router.push("/merchant-landing-new") ;
        }
      });
  },
  computed: {
    lists: {
      get() {
        return this.$store.state.breadcrumb.breadcrumb.payload;
      }
    },
    activeStoresList: {
      get() {
        return this.$store.state.storeManagement.activeList.action;
      }
    }
  },
  watch: {
    "$store.state.merchant_full_name"() {
      if (this.$store.state.merchant_full_name === null) {
        this.getMerchantFullName();
      } else {
        this.fullName = "";
        this.fullName = this.$store.state.merchant_full_name;
      }
    },
    activeStoresList: function(val) {
      if (val === true) {
        this.handleGetStores();
      }
    },
    $route(to, from) {
      this.leftMenuGen();
      this.zoomContainerRemove();
      this.checkRequiredStage();
    },
    "$route.name"() {
      if (
        this.$route.name === "Product Configure" ||
        this.$route.name === "Product Configure Bulk" ||
        this.$route.name === "Stock Configure Bulk Sub"
      ) {
        this.selected_store = this.$store.state.merchant_selectedstore;
      } else {
        if (this.store.length === 1) {
          this.selected_store = this.store[0]._id
          this.$store.state.merchant_selectedstore = this.selected_store;
        } else {
          this.selected_store = "-1";
        }
      }
      this.checkRequiredStage();
    },
    selected_store: function (val) {
      localStorage.setItem("merchant_selectedstore", this.selected_store);
      this.$store.dispatch("fetchMerchant_selectedstore");
    },
  },
  beforemounted() {
    /* ---------------------------------------------------
        Back to Top
      -------------------------------------------------- */
    $(".back-to-top").addClass("hidden-top");
    $(window).scroll(function () {
      if ($(this).scrollTop() === 0) {
        $(".back-to-top").addClass("hidden-top");
      } else {
        $(".back-to-top").removeClass("hidden-top");
      }
    });

    $(".back-to-top").click(function () {
      $("body,html").animate({ scrollTop: 0 }, 1200);
      return false;
    });
  },
  created() {
    this.getMerchantFullName();
    this.handleBreadcrumb();
    this.handleGetStores();
    this.zoomContainerRemove();
    this.checkRequiredStage();
    this.leftMenuGen();
  },
  methods: {
    logoutMerchant: async function (e) {
      e.preventDefault();
      e.returnValue = "";
      try {
        localStorage.removeItem("merchant_token");
        localStorage.removeItem("merchant_type");
        localStorage.removeItem("login_merchant");
        localStorage.removeItem("merchant_data");
        this.$store.dispatch("logoutMerchant");
        this.$router.push("/merchant-landing-new") ;
      } catch (error) {
        alert(error)
      }
    },
    getMerchantFullName: async function () {
      try {
        let response = await this.getProfileBacicInfo();
        this.fullName = response.first_name + " " + response.last_name;
        // if (response.active_status === 2) {
          if (response.kyc_verify.includes("2")) {
            if (response.kyc_lists) {
              if (response.kyc_lists[0].image_approve_status) {
                if (response.kyc_lists[0].image_approve_status === "rejected") {
                  this.$toast.error("Your Kyc Details are Rejected. Please Resubmit Your Kyc Details.", "Rejected", {
                    position: "topRight",
                    close: true,
                    timeout: 0,
                  });
                }
              }
              if (response.kyc_lists[0].address_image_approve_status) {
                if (response.kyc_lists[0].address_image_approve_status === "rejected") {
                  this.$toast.error("Your Address Proof Details are Rejected. Please Resubmit Your Address Proof Details.", "Rejected", {
                    position: "topRight",
                    close: true,
                    timeout: 0,
                  });
                }
              }
              if (response.kyc_lists[0].bank_approve_status) {
                if (response.kyc_lists[0].bank_approve_status === "rejected") {
                  this.$toast.error("Your Bank Details are Rejected. Please Resubmit Your Bank Details.", "Rejected", {
                    position: "topRight",
                    close: true,
                    timeout: 0,
                  });
                }
              }
              if (response.kyc_lists[0].certificate_approve_status) {
                if (response.kyc_lists[0].certificate_approve_status === "rejected") {
                  this.$toast.error("Your Business Registration Details are Rejected. Please Resubmit Your Business Registration Details.", "Rejected", {
                    position: "topRight",
                    close: true,
                    timeout: 0,
                  });
                } 
              }
            }
          }
      } catch (error) {
        localStorage.removeItem("merchant_token");
        localStorage.removeItem("merchant_type");
        localStorage.removeItem("login_merchant");
        localStorage.removeItem("merchant_data");
        this.$store.dispatch("logoutMerchant");
        this.$router.push("/merchant-landing-new") ;
        throw error;
      }
    },
    checkRequiredStage: async function () {
      let mdata = JSON.parse(localStorage.merchant_data);
      this.userRole = mdata.role;
      this.account_type = mdata.account_type;
      let response = await this.getDashboardStage();

      if (response !== null) {
        this.showStages = true;
        this.currentstage = response.complated_status;
        this.checkStageStatus(this.currentstage)
        return response.complated_status;
      } else {
        this.showStages = false;
        this.currentstage = 1;
        this.checkStageStatus(this.currentstage)
        return null;
      }
    },
    handleBreadcrumb: function () {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    zoomContainerRemove() {
      $(".zoomContainer").each(function () {
        //if statement here
        // use $(this) to reference the current div in the loop
        //you can try something like...
        $(this).remove();
      });
    },
    breadpush(pathlink) {
      this.$router.push(pathlink) ;
    },
    validateStoreData(data) {
      for (let index = 0; index < data.length; index++) {
        if (data[index].addresses.length === 0) {
          let path = "/merchant-store-configure/" + data[index]._id;
          if (this.$route.path === path) {
            return;
          }
          this.$router.push(path);

          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Please Update Store Address",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    checkStageStatus: function(id) {
      if (this.account_type === 1) {
        if (id === 1 || id === 2 || id === 3|| id === 6) {
          this.$router.push("/merchant-profile");
        }
      } else if (this.account_type === 2) {
        if (id === 1 || id === 2 || id === 3|| id === 6 ) {
          this.$router.push("/merchant-profile");
        }
      }
    },
    handleGetStores: async function () {
      try {
        let response = await this.getStores();
        this.store = response;

        this.store = this.store.filter(el => {
          return el.active_status === 1;
        });
        
        this.validateStoreData(response);
        if (this.store.length === 0) {
          this.mid = "";
          localStorage.setItem("merchant_selectedstore", "");
          this.$store.dispatch("fetchMerchant_selectedstore");
        } else {
          this.mid = this.store[0].merchant;
          if (localStorage.merchant_selectedstore) {
            this.$store.dispatch("fetchMerchant_selectedstore");

            this.selected_store = this.$store.state.merchant_selectedstore;
          } else {
            if (response.length === 1) {
              this.selected_store = response[0]._id;
              localStorage.setItem("merchant_selectedstore", response[0]._id);
              this.$store.dispatch("fetchMerchant_selectedstore");
            } else {
              this.selected_store = "-1";
              localStorage.setItem("merchant_selectedstore", "-1");
              this.$store.dispatch("fetchMerchant_selectedstore");
            }
          }
        }

        let isUpdate = false;
        this.$store.commit("setActiveStore", {
          action: isUpdate,
          payload: null
        });

      } catch (error) {
        console.log(error);
      }
    },
    leftMenuGen() {
      let links;
      if (this.userRole === 1) {
        links = sidebarLinksMerchant;
      } else if (this.userRole === 2) {
        links = sidebarLinksMerchantRole2;
      } else if (this.userRole === 4) {
        links = sidebarLinksMerchant;
      } else if (this.userRole === 5) {
        links = sidebarLinksMerchant;
      } else if (this.userRole === 6) {
        links = sidebarLinksMerchantRole7;
      } else if (this.userRole === 7) {
        links = sidebarLinksMerchantRole7;
      }

      links.forEach((element) => {
        if (element.subpaths) {
          if (
            element.subpaths.includes(this.$route.name) ||
            element.path === this.$route.path
          ) {
            element.active = true;
          } else {
            element.active = false;
          }
        } else {
          if (element.path === this.$route.path) {
            element.active = true;
          } else {
            element.active = false;
          }
        }
      });

      this.leftmenudata = { title: "menu", linksArray: links };
    },
  },
};

</script>

<style>
.well {
  background-color: transparent !important;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}

#homeIcon:hover,
#homeIcon:active,
#homeIcon:after {
  color: #5779ae !important;
}

/* three items */
.progressbar li:first-child:nth-last-child(4),
.progressbar li:first-child:nth-last-child(4) ~ li {
  width: 25%;
}

.progressbar li:first-child:nth-last-child(5),
.progressbar li:first-child:nth-last-child(5) ~ li {
  width: 20%;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: no-close-quote;
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: rgb(77, 161, 48);
}
.progressbar li.active:before {
  border-color: rgb(77, 161, 48);
  /* background-color: rgb(77, 161, 48); */
  /* background-image: url('../../../../assets/image/sample_product_images/confirm1.png'); */
  background-image: url("/assets/image/sample_product_images/confirm1.png");
  background-size: contain;
}
.progressbar li.active + li:after {
  background-color: rgb(77, 161, 48);
}

.progressbar li.dfailed:before {
  border-color: red;
  background-color: red !important;
  background-image: url("https://www.shareicon.net/data/256x256/2015/09/15/101562_incorrect_512x512.png");
  background-size: contain;
}

.home-bedcrumb {
  font-size: 20px;
  color: #000000;
}

ul.breadcrumb li:not(:last-child):after {
  font-family: "FontAwesome";
  content: none !important;
  font-size: 13px;
  top: 0px;
  right: 0;
  position: absolute;
}
ul.breadcrumb li {
  display: inline-block;
  padding: 0 17px 0 8px;
  margin-right: 0px;
  position: relative;
}
ul.breadcrumb li a:hover {
  color: #5779ae;
}
ul.breadcrumb li:last-child a {
  color: #5779ae;
}
.mer-info-block {
  padding-left: 5.5%;
}
.mer-info-block h3 {
  margin-top: 0px;
}
@media (min-width: 768px) {
  .scoped {
    width: 36%;
  }
}
@media (min-width: 1280px) {
  .scoped {
    width: 31%;
  }
}
@media (min-width: 1440px) {
  .scoped {
    width: 31%;
  }
}
@media (min-width: 1800px) {
  .scoped {
    width: 25%;
  }
}
@media (min-width: 1880px) {
  .scoped {
    width: 25%;
  }
}
@media (min-width: 2880px) {
  .scoped {
    width: 25%;
  }
}
</style>
