export default [{
        name: 'Dashboard',
        icon: 'nc-icon nc-bank',
        path: '/merchant-dashbord'
    },
    {
        name: 'Gallery',
        icon: 'nc-icon nc-bank',
        path: '/gallery-list'
    },
    {
        name: "Order Management",
        path: "/merchant-order-group",
        icon: "nc-icon nc-bank",
        subpaths: ["Order Configure", "Order List Group", "Order Lines"],
        // child: [{name: 'Order List', path: '/merchant-order-list'},{name: 'Manager Reviews', path: '#'}]
    },
    // add pending approve it admin
    // {
    //   name: "Pending Approval",
    //   icon: "nc-icon nc-bank",
    //   path: "/merchant-pending-order",
    //   subpaths: ["Pending Order Lines"],
    // },
    {
        name: "Cancelled Orders",
        icon: "nc-icon nc-bank",
        path: "/merchant-cancelled-order",
        subpaths: ["Cancelled Order Lines"],
    },
    {
        name: "Profile",
        icon: "nc-icon nc-bank",
        path: "/merchant-profile",
    },
    {
        name: "Store Management",
        icon: "nc-icon nc-bank",
        path: "/merchant-store",
        subpaths: ["Store Banner", "Store Configure"],
    },
    {
        name: "Product Overview",
        path: "/product-store",
        icon: "nc-icon nc-bank",
        subpaths: ["Product Configure", "Product Configure Bulk"],
        // child: [{name: 'Products', path: '/product-store'},{name: 'Media Center', path: '#'}]
    },
    {
        name: 'Dispute & Return',
        icon: 'nc-icon nc-bank',
        path: '/merchant-dispute-list',
        subpaths: ['Merchant Dispute Lines']
    },
    // {
    //   name: 'Promotions',
    //   icon: 'nc-icon nc-bank',
    //   path: '/merchant-promotions-list',
    //   subpaths: ['Promotion Configure To Product']
    // },
    {
        name: 'Reports',
        icon: 'nc-icon nc-bank',
        path: '/merchant-report-list',
        // subpaths: ['Merchant Return Orders Configure']
    },
    // {
    //   name: "Preferences",
    //   icon: "nc-icon nc-bank",
    //   path: "/merchant-preferences",
    // },
    // {
    //   name: "Finance",
    //   icon: 'nc-icon nc-bank',
    //   path: '#',
    //   // child: [{name: 'Account Statement', path: '#'},{name: 'Order Overview', path: '#'},{name: 'Transaction Overview', path: '#'}]
    // },
    // {
    //   name: "Vouchers",
    //   icon: 'nc-icon nc-bank',
    //   path: '#',
    // },
    // {
    //   name: 'Promotions',
    //   icon: 'nc-icon nc-bank',
    //   path: '/merchant-promotions-list',
    //   subpaths: ['Promotion Configure To Product']
    // },
    {
        name: 'Notification Center',
        icon: 'nc-icon nc-bank',
        path: '/merchant-notification-center'
    }
];