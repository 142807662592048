import { render, staticRenderFns } from "./TopNavbarMerchantPortal.vue?vue&type=template&id=79b9aaf3&scoped=true&"
import script from "./TopNavbarMerchantPortal.vue?vue&type=script&lang=js&"
export * from "./TopNavbarMerchantPortal.vue?vue&type=script&lang=js&"
import style0 from "./TopNavbarMerchantPortal.vue?vue&type=style&index=0&id=79b9aaf3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b9aaf3",
  null
  
)

export default component.exports